export const glossaryItems = {
	Revenue:
		"The total amount of income generated by the sale of goods or services related to the company's primary operations.",

	TTM: 'TTM (Trailing Twelve Months) is a method of evaluating financial figures by using the data from the past 12 consecutive months. For TTM revenue, the revenues of the last four quarters for each company are added together.',
	'Net Income':
		'The amount of profit left after subtracting all expenses, taxes, and costs from revenue.',
	'Gross Margin':
		'The difference between revenue and cost of goods sold (COGS), divided by revenue, here expressed as a percentage.',

	YoY: 'YoY (Year-over-Year) compares the results of a specific period with those of the same period in the previous year. It helps to recognize changes and trends over time more easily. For example: sales in July 2023 are compared with sales in July 2022.',
	'Revenue Chart':
		'Displays the revenue development of the 7 companies over the last 3 years. The x-axis shows the quarters of the last 3 years in chronological order. A separate graph is drawn for each of the 7 companies, representing the revenue trend over the quarters.',
	'Revenue Breakdown Chart':
		"Shows the revenue differences between the seven companies based on their TTM revenues. Each segment (slice) of the donut chart represents a company's TTM revenue. The seven companies are represented by different colors in the donut chart to visually distinguish them from each other.",
	'Net Income TTM Chart':
		'Visualizes and compares the net profit of the 7 companies in the Trailing Twelve Months (TTM) period as a bar chart. Absolute values are shown in billions of US dollars.',
	'Gross Margin % LQ (Last Quarter)':
		'Visualizes and compares the gross margin as a percentage from the last reported quarter. It displays the most recently reported gross margin ratio as a percentage for each company.',
	'Revenue Growth YoY %':
		'Visualizes the revenue growth over the last 4 quarters in comparison. The chart shows the percentage revenue growth (YoY) for the last 4 completed quarters compared to the same quarter of the previous year. For each company, 4 bars are displayed - one for each quarter. Each bar shows the growth compared to the same quarter of the previous year.',
};

export function getGlossaryItems() {
	return Object.entries(glossaryItems).map(([title, explanation]) => ({
		title,
		explanation,
	}));
}
